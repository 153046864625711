.app {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.resultsContainer {
    flex-direction: column;
}

.results {
    margin-top: 1rem;
}

.resultsLabel {
    margin-right: 0.5rem;
    font-weight: bold;
}
